<template>
  <div class="payment payment-completed">
    <v-container>
      <h1 class="primary--text">{{ $t("images.title") }}</h1>
      <!-- <Breadcrumb class="hidden-sm-and-down px-0" :items="breadcrumbs" /> -->

      <h2 class="pt-12">Richiesta download #{{ order.orderId }}</h2>
      <p
        class="pt-12"
        v-if="order.orderStatusId == 6 || order.orderStatusId == 8"
      >
        {{ $t("order.orderReady") }}
      </p>
      <p class="pt-12" v-else>
        {{ $t("images.fourthStep.subTitle") }}
      </p>
    </v-container>
    <!-- <div
      class="d-flex align-center flex-column justify-center flex-wrap title-wrap px-2"
    >
      <v-img
        width="72"
        height="72"
        class="mb-7"
        :src="
          paymentFailed
            ? require('@/assets/payment/ecommerce-ko.svg')
            : require('@/assets/payment/ecommerce-ok.svg')
        "
      ></v-img>
      <h2 v-if="paymentFailed" class="mb-10 ko text-uppercase text-center">
        {{ $t("paymentCompleted.failure.title") }}
      </h2>
      <h2 v-else class="mb-10 ok text-uppercase">
        {{ $t("paymentCompleted.success.title") }}
      </h2>
    </div> -->

    <!-- pulsanti -->
    <v-container
      class="buttons d-flex justify-center flex-wrap"
      :class="
        $vuetify.breakpoint.xsOnly && paymentFailed ? 'flex-column' : 'flex-row'
      "
    >
      <v-btn outlined color="primary" class="mx-2 mx-sm-10" v-bind:to="'/'">
        {{ $t("paymentCompleted.button.home") }}
      </v-btn>
      <v-spacer v-if="$vuetify.breakpoint.lgAndUp && paymentFailed"></v-spacer>
      <v-btn
        color="primary"
        class="justify-self-md-end my-6 my-sm-0 mx-2 mx-sm-10"
        outlined
        v-if="paymentFailed"
        @click="toggleShowPayment"
      >
        {{ $t("paymentCompleted.button.retry") }}
      </v-btn>
      <div
        v-if="order && (order.orderStatusId == 6 || order.orderStatusId == 8)"
      >
        <div v-if="order.orderInfos && order.orderInfos.zip_file_url">
          <v-btn
            color="primary"
            :href="order.orderInfos.zip_file_url"
            class="mx-2 mx-sm-10 download-btn"
            depressed
            >Download</v-btn
          >
        </div>
        <div class="d-flex" v-else>
          <!-- <v-col cols="12" sm="6" md="4" class="a-center"> -->
          <v-select
            :items="urls"
            v-model="selectedUrl"
            outlined
            hide-details
            flat
            dense
            solo
            :label="$t('common.select')"
            item-text="label"
            item-value="url"
          ></v-select>
          <!-- </v-col> -->
          <!-- <v-col cols="12" sm="6" md="3"> -->
          <v-btn
            :href="selectedUrl"
            color="primary"
            class="mx-2 mx-sm-10 download-btn"
            depressed
            :disabled="disableDownload"
            >Download</v-btn
          >
          <!-- </v-col> -->
        </div>
      </div>
      <v-btn
        color="primary"
        class="justify-self-md-end my-6 my-sm-0 mx-2 mx-sm-10"
        outlined
        v-else
        @click="refreshOrder"
      >
        Refresh
      </v-btn>
      <v-btn
        v-bind:to="'/profile/orders/' + order.orderId"
        color="primary"
        class="mx-2 mx-sm-10"
        depressed
      >
        {{ $t("paymentCompleted.button.showOrder") }}
      </v-btn>
    </v-container>
  </div>
</template>

<script>
import OrderService from "~/service/orderService";
//import Breadcrumb from "@/components/navigation/Breadcrumb";
import categoryMixins from "~/mixins/category";
import AnalyticsService from "~/service/analyticsService";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "PaymentCompleted",
  mixins: [categoryMixins],
  //components: { Breadcrumb },
  data() {
    return { order: {}, showPayment: false, selectedUrl: null };
  },
  computed: {
    ...mapGetters({
      isOrderSentToAnalytics: "app/isOrderSentToAnalytics"
    }),
    breadcrumbs() {
      let breadCrumbs = [];

      breadCrumbs.push({
        to: { name: "Home" },
        text: "Homepage",
        exact: true
      });
      breadCrumbs.push({
        to: {
          name: "PaymentCompleted",
          params: {
            pathMatch: this.category.slug
          }
        },
        text: this.category.name,
        exact: true
      });
      return breadCrumbs;
    },
    paymentFailed() {
      return (
        this.order.orderStatusId == 2 ||
        this.order.orderStatusId == 11 ||
        this.order.orderStatusId == 1
      );
    },
    disableDownload() {
      return this.selectedUrl === null ? true : false;
    },
    urls() {
      if (this.order) {
        let urls = [];
        console.log("TEST", this.order.orderInfos);
        for (const property in this.order.orderInfos) {
          if (property.startsWith("zip_file_url_part")) {
            let index = this.order.orderInfos[property].lastIndexOf("_");
            console.log(
              this.order.orderInfos[property]
                .substring(index + 1)
                .replace(".zip", "")
            );
            urls.push({
              url: this.order.orderInfos[property],
              label:
                "Part " +
                this.order.orderInfos[property]
                  .substring(index + 1)
                  .replace(".zip", "")
            });
          }
        }
        return urls;
      } else return [];
    }
  },
  methods: {
    ...mapActions({
      addOrderSentToAnalytics: "app/addOrderSentToAnalytics"
    }),
    async fetchOrder(orderId) {
      let vm = this;
      vm.order = await OrderService.getOrder(orderId);
      if (vm.order.orderStatusId == 3) {
        if (!vm.isOrderSentToAnalytics(orderId)) {
          try {
            AnalyticsService.purchase(vm.order);
            vm.addOrderSentToAnalytics(orderId);
          } catch (e) {
            console.log(e);
          }
        }
      }
    },
    refreshOrder() {
      let vm = this;
      vm.fetchOrder(this.$route.params.orderId);
    },
    toggleShowPayment() {
      this.showPayment = !this.showPayment;
    }
  },
  beforeDestroy() {
    global.EventBus.$off("pay-response-ok");
  },
  mounted() {
    let vm = this;
    vm.fetchOrder(this.$route.params.orderId);
    global.EventBus.$on("pay-response-ok", order => {
      vm.showPayment = false;
      vm.fetchOrder(order.orderId);
    });
  },
  metaInfo() {
    return {
      title: this.$t("images.title")
    };
  }
};
</script>
